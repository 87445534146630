import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Components
import Home from "./pages/Home";
import ExpeditionTemplate from "./pages/Expeditions/ExpeditionTemplate";
import IndividualExpedition from "./pages/IndividualExpedition/IndividualExpedition";
import WhoWeAre from "./pages/WhoWeAre";
import WhyWithUs from "./pages/WhyWithUs";
import OurTeam from "./pages/OurTeam";
import Certificates from "./pages/Certificates";
import IndividualCertificate from "./pages/IndividualCertificate/IndividualCertificate";
import AchievementsRecords from "./pages/AchievementsRecords";
import IndividualAchievementsRecords from "./pages/IndividualAchievementsRecords/IndividualAchievementsRecords";
import GetInTouch from "./pages/GetInTouch";
import IndividualWildlife from "./pages/IndividualWilldlifePhptpography.js/IndividualWildlife";
import IndividualAdventureTours from "./pages/IndividualAdventureTours/IndividualAdventureTours";
import IndividualTeamMember from "./pages/IndividualTeamMember/IndividualTeamMember";
import PlanYourTrip from "./pages/PlanYourTrip";
import TermsConditions from "./pages/Terms&conditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import { UserAuthContextProvider } from "./utils/UserAuthContext";
import SachPass from "./pages/SachPass";
import WinterSpiti from "./pages/WinterSpiti";
import WriteReview from "./pages/WriteReview";
import Blog1 from "./pages/Blogs/Blog/Blog1";
import Blog2 from "./pages/Blogs/Blog/Blog2";
import Blog3 from "./pages/Blogs/Blog/Blog3";

function App() {
  return (
    <Router>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route
            path="/:expeditionType"
            exact
            element={<ExpeditionTemplate />}
          />
          <Route
            path="/expedition/:individualExpeditionType"
            exact
            element={<IndividualExpedition />}
          />
          <Route
            path="/wildlifePhotography/:individualWildlifePhotographyType"
            exact
            element={<IndividualWildlife />}
          />
          <Route
            path="/adventureTours/:individualadventureTourType"
            exact
            element={<IndividualAdventureTours />}
          />
          <Route
            path="/adventureTour/sachPasKishtwarOverlanding"
            exact
            element={<SachPass />}
          />
          <Route
            path="/adventureTour/winterSpitiOverlanding"
            exact
            element={<WinterSpiti />}
          />
          <Route
            path="/whiteExpeditionLeader/:individualLeader"
            exact
            element={<IndividualTeamMember />}
          />
          <Route path="/whoWeAre" exact element={<WhoWeAre />} />
          <Route path="/whyWithUs" exact element={<WhyWithUs />} />
          <Route path="/getInTouch" exact element={<GetInTouch />} />
          <Route path="/planYourTrip" exact element={<PlanYourTrip />} />
          <Route path="/ourTeam" exact element={<OurTeam />} />
          <Route path="/certificates" exact element={<Certificates />} />
          <Route
            path="/termsAndConditions"
            exact
            element={<TermsConditions />}
          />
          <Route path="/privacyPolicy" exact element={<PrivacyPolicy />} />
          <Route
            path="/cancellationPolicy"
            exact
            element={<CancellationPolicy />}
          />
          <Route
            path="/certificates/:individualCertificate"
            exact
            element={<IndividualCertificate />}
          />
          <Route
            path="/AchievementsRecords"
            exact
            element={<AchievementsRecords />}
          />
          <Route path="/WriteReview" exact element={<WriteReview />} />
          <Route
            path="/AchievementsRecords/:individualAchievementsRecords"
            exact
            element={<IndividualAchievementsRecords />}
          />
          <Route
            path="/blogs/how-to-start-mountaineering-in-india-step-by-step-guide"
            exact
            element={<Blog1 />}
          />
          <Route
            path="/blogs/beginner-friendly-6000m-peaks-high-altitude-mountaineering"
            exact
            element={<Blog2 />}
          />
          <Route
            path="/blogs/learning-skiing-snowboarding-india-gulmarg-ultimate-destination"
            exact
            element={<Blog3 />}
          />
        </Routes>
      </UserAuthContextProvider>
    </Router>
  );
}

export default App;
