import { Link } from "react-router-dom";

const BlogCard = ({ title, date, image, link }) => {
  return (
    <div className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-1 md:col-span-6 md:h-[420px] lg:col-start-1 lg:col-span-6 lg:h-[300px] xl:col-start-1 xl:col-span-6 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md group hover:cursor-pointer">
      <Link
        to={link}
        onClick={() => {
          window.scrollTo(0, 0); // Scroll to the top of the page
        }}
      >
        <img src={image} alt={title} className="w-full h-full object-cover" />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
        <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-end items-start px-4 pb-8">
          <h1 className="newContent text-start text-white">{date}</h1>
          <h1 className="newL2SubHeader text-start margin text-primary group-hover:text-primary">
            {title}
          </h1>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
